<div
  role="alert"
  ui-card
  [borders]="borders"
  [classes]="classes"
  [ngClass]="{
    'bg-info-base dark:bg-info-base text-info-base bg-opacity-15 dark:bg-opacity-20 dark:text-white':
      color === 'info',
    'bg-success-pale text-success-base': color === 'success'
  }"
>
  <div class="flex">
    <div class="text-info-base" #icon>
      <ng-content select="alert-icon"></ng-content>
    </div>
    <div
      class="flex flex-1 flex-col items-start space-y-1"
      [ngClass]="{ 'ml-4': icon.childNodes.length !== 0 }"
    >
      <div
        class="w-full font-medium"
        [ngClass]="{ 'text-success-active': color === 'success' }"
      >
        <ng-content select="alert-title"></ng-content>
      </div>
      <ng-content select="alert-content"></ng-content>
    </div>
  </div>
</div>
