import { Attribute, BaseResource } from '@lysties/common/api';
import { timeToDate } from '@lysties/common/helpers';

export class OpeningHours extends BaseResource {
  @Attribute()
  weekday = 0;

  @Attribute(undefined, undefined, timeToDate)
  openingTime = '';

  @Attribute(undefined, undefined, timeToDate)
  closingTime = '';

  @Attribute()
  closesNextDay = false;
}
