import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';

import { forkJoin, of } from 'rxjs';

import { AuthService } from '@lysties/auth/data-access';
import { User } from '@lysties/users/data-access';

export const appResolver: ResolveFn<(User | null)[]> = () => {
  const auth = inject(AuthService);
  const observables = [auth.isAuthenticated(true) ? auth.loadUser() : of(null)];

  return forkJoin(observables);
};
