import { Attribute, BaseResource } from '@lysties/common/api';
import { Profile } from './profile';

export class User extends BaseResource {
  @Attribute()
  email = '';

  @Attribute(Profile)
  profile!: Profile;

  constructor(init?: Partial<User>) {
    super();

    Object.assign(this, init);
  }
}
