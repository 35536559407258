import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  booleanAttribute,
} from '@angular/core';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { heroStarSolid } from '@ng-icons/heroicons/solid';
import { Star } from './star';

@Component({
  selector: 'ui-rating',
  templateUrl: './rating.component.html',
  imports: [CommonModule, NgIconComponent],
  standalone: true,
  viewProviders: [provideIcons({ heroStarSolid })],
})
export class RatingComponent implements OnInit {
  @Input() mode: 'read' | 'write' = 'read';
  @Input() size = 24;
  @Input() max = 5;
  @Input() rating = 0;
  @Input() count = 0;
  @Input({ transform: booleanAttribute }) displayValue = false;
  @Output() readonly changed = new EventEmitter<number>();
  stars: Star[] = [];
  selectedRating = 0;

  ngOnInit(): void {
    this.setupStars();
    this.updateState(this.rating);
  }

  getStarClass(star: Star): string {
    if (star.fill === 0) {
      return '';
    }

    if (this.rating <= 1) {
      return 'bg-[#ff0f0f]';
    }

    if (this.rating <= 2) {
      return 'bg-[#f57c00]';
    }

    if (this.rating <= 3) {
      return 'bg-[#fbbc04]';
    }

    if (this.rating <= 4) {
      return 'bg-[#1f47f5]';
    }

    return 'bg-[#3f9a63]';
  }

  onMouseEnter(star: Star): void {
    if (this.mode === 'write') {
      this.rating = star.index + 1;
      this.updateState(this.rating);
    }
  }

  onMouseLeave(): void {
    if (this.mode === 'write') {
      this.rating = this.selectedRating;
      this.updateState(this.rating);
    }
  }

  selectRating(star: Star): void {
    this.selectedRating = star.index + 1;
    this.changed.emit(this.selectedRating);
  }

  private updateState(rating: number): void {
    this.stars.forEach(
      (star: Star, index: number) =>
        (star.fill = Math.round(
          Math.max(Math.min(rating - index, 1), 0) * 100,
        )),
    );
  }

  private setupStars(): void {
    this.stars = Array.from({ length: this.max }, (v, k) => ({
      fill: 0,
      index: k,
    }));
  }
}
