<div class="flex items-center">
  @for (star of stars; track star.index) {
    <button
      type="button"
      (mouseenter)="onMouseEnter(star)"
      (focus)="onMouseEnter(star)"
      (blur)="onMouseLeave()"
      (mouseleave)="onMouseLeave()"
      class="dark:bg-dark-active relative mr-1 flex overflow-hidden rounded-full bg-neutral-300"
      [ngClass]="{
        'hover:cursor-pointer': mode === 'write',
        'hover:cursor-default': mode === 'read'
      }"
      (click)="selectRating(star)"
    >
      <div
        class="flex w-full overflow-visible p-1 text-white transition-all duration-300 ease-in-out"
      >
        <div
          class="absolute inset-0"
          [ngStyle]="{ width: star.fill + '%' }"
          [ngClass]="getStarClass(star)"
        ></div>
        <ng-icon
          name="heroStarSolid"
          [size]="size.toString()"
          class="z-[5]"
        ></ng-icon>
      </div>
    </button>
  }

  @if (mode === 'write') {
    <p class="text-muted-base dark:text-dark-muted ml-2 inline-block text-sm">
      <span *ngIf="rating === 0" i18n>Select your rating</span>
      <span *ngIf="rating === 1" i18n>Not good</span>
      <span *ngIf="rating === 2" i18n>Could have been better</span>
      <span *ngIf="rating === 3" i18n>OK</span>
      <span *ngIf="rating === 4" i18n>Good</span>
      <span *ngIf="rating === 5" i18n>Great</span>
    </p>
  } @else {
    @if (displayValue && rating !== 0) {
      <strong class="ml-2 font-bold">{{ rating }}</strong>
    }
  }
</div>
