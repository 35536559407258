import { Metadata, attributeMetadata } from './metadata';
import { BaseResource } from './resource';

export function Attribute(
  type?: unknown,
  attribute?: string,
  transform?: (value: unknown) => unknown,
) {
  return function (target: BaseResource, property: string) {
    if (!attributeMetadata.has(target.constructor)) {
      attributeMetadata.set(target.constructor, []);
    }

    const metadata: Metadata = {
      attribute: attribute || property,
      property,
      type,
      transform: transform || ((value) => value),
    };

    attributeMetadata.get(target.constructor)?.push(metadata);
  };
}
