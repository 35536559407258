import { BaseResource, Resource } from './resource';
import { attributeMetadata } from './metadata';

class Base extends BaseResource {}

type Constructor<T> = new () => T;

/**
 * Returns true if the given object is of a primitive type.
 *
 * @param obj the object to tell if it is of primitive type.
 */
function isPrimitive(obj: unknown): boolean {
  // Primitive types are string, number and boolean.
  switch (typeof obj) {
    case 'string':
    case 'number':
    case 'boolean':
    case 'undefined':
      return true;
    default:
      return (
        obj instanceof String ||
        obj === String ||
        obj instanceof Number ||
        obj === Number ||
        obj instanceof Boolean ||
        obj === Boolean
      );
  }
}

/**
 * Deserializes the given data into an instance of the specified class.
 *
 * @template T - The type of the class to deserialize into.
 * @param cls - The constructor function of the class.
 * @param data - The resource data to deserialize.
 * @param related - The related resources.
 * @returns The deserialized instance of the class.
 */
export function deserialize<T extends BaseResource>(
  cls: Constructor<T>,
  data: Resource,
  related: Resource[],
): T {
  const resource = new cls();
  const metadata = attributeMetadata.get(resource.constructor);
  resource.id = data.id;
  related = related || [];

  if (!metadata) return resource;

  for (const { attribute, property, type, transform } of metadata) {
    const attributeValue = data.attributes?.[attribute];
    const relationshipData = data.relationships?.[attribute]?.data;

    if (isPrimitive(type)) {
      resource[property as keyof T] = transform(attributeValue) as T[Extract<
        keyof T,
        string
      >];
      continue;
    }

    if (type && new (type as typeof Base)() instanceof Date && attributeValue) {
      resource[property as keyof T] = new Date(
        attributeValue as string,
      ) as T[Extract<keyof T, string>];
    }

    if (!relationshipData || !type) continue;

    if (Array.isArray(relationshipData)) {
      const relatedResources = relationshipData
        .map((item: Resource) =>
          related.find((r) => r.id === item.id && r.type === item.type),
        )
        .filter(
          (relatedResource): relatedResource is Resource =>
            relatedResource !== undefined,
        )
        .map((relatedResource) =>
          deserialize(type as typeof Base, relatedResource, related),
        );

      resource[property as keyof T] = relatedResources as T[Extract<
        keyof T,
        string
      >];
      continue;
    }

    const relatedResource = related.find(
      (r) => r.id === relationshipData.id && r.type === relationshipData.type,
    );
    if (relatedResource) {
      resource[property as keyof T] = deserialize(
        type as typeof Base,
        relatedResource,
        related,
      ) as T[Extract<keyof T, string>];
    }
  }

  return resource;
}

/**
 * Serializes the given data into a resource object.
 *
 * @template T - The type of the resource.
 * @param cls - The class constructor of the resource.
 * @param data - The data to be serialized.
 * @param type - The type of the resource (optional).
 * @returns The serialized resource object.
 */
export function serialize<T extends BaseResource>(
  cls: Constructor<T>,
  data: unknown,
  relationship = false,
): { data: Resource } {
  const obj = new cls();
  const resource: Partial<T> = { ...(data as T) };
  const metadata = attributeMetadata.get(obj.constructor);
  const payload: { data: Resource } = {
    data: {
      type: obj.constructor.name,
    },
  };

  if (resource.id) {
    payload.data.id = resource.id;
  }

  if (relationship) return payload;

  payload.data.attributes = {};
  payload.data.relationships = {};

  for (const key in data as object) {
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      const value = (data as Record<string, unknown>)[key];
      const attributeMetadata = metadata?.find((m) => m.property === key);

      if (attributeMetadata) {
        const { attribute, type } = attributeMetadata;

        if (isPrimitive(type)) {
          payload.data.attributes[attribute] = value;
          continue;
        }

        if (type === Date) {
          payload.data.attributes[attribute] =
            value instanceof Date
              ? `${value.getFullYear()}-${value.getMonth() + 1}-${value.getDate()}`
              : null;
          continue;
        }

        if (Array.isArray(value)) {
          (payload.data.relationships as Record<string, unknown>)[key] = {
            data: value.map(
              (item) =>
                serialize(type as Constructor<BaseResource>, item, true).data,
            ),
          };
          continue;
        }

        if (value instanceof BaseResource && value.id) {
          payload.data.relationships[key] = serialize(
            type as Constructor<BaseResource>,
            value,
            true,
          );
        }
      }
    }
  }

  return payload;
}
