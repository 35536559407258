import { Attribute, BaseResource } from '@lysties/common/api';

export class Photo extends BaseResource {
  @Attribute()
  authorId = '';

  @Attribute()
  authorName = '';

  @Attribute()
  businessName = '';

  @Attribute()
  businessSlug = '';

  @Attribute()
  caption = '';

  @Attribute(Date)
  created = new Date();

  @Attribute()
  image = '';

  constructor(image = '') {
    super();

    this.image = image;
  }
}
