import { Attribute, BaseResource } from '@lysties/common/api';

export class Region extends BaseResource {
  @Attribute() name = '';
  @Attribute() slug = '';
  @Attribute() code = '';

  constructor(init?: Partial<Region>) {
    super(init);

    Object.assign(this, init);
  }
}
