import { CommonModule } from '@angular/common';
import {
  Component,
  HostListener,
  Inject,
  Input,
  OnInit,
  booleanAttribute,
} from '@angular/core';
import { RouterModule } from '@angular/router';

import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { featherMenu, featherX } from '@ng-icons/feather-icons';
import {
  matDarkModeRound,
  matLightModeRound,
  matDesktopMacRound,
} from '@ng-icons/material-icons/round';

import { AuthService } from '@lysties/auth/data-access';
import { BusinessesSearchBarComponent } from '@lysties/businesses/search-bar';
import {
  ButtonComponent,
  MenuComponent,
  MenuTriggerForDirective,
} from '@lysties/shared/ui';
import { ThemeService } from '@lysties/shared/theming';
import { User } from '@lysties/users/data-access';
import { WINDOW_TOKEN } from '@lysties/common/browser';

/**
 * Header component.
 * Provides a standard header with a logo, a menu and a search bar.
 */
@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    NgIconComponent,
    ButtonComponent,
    MenuTriggerForDirective,
    MenuComponent,
    BusinessesSearchBarComponent,
  ],
  templateUrl: './header.component.html',
  viewProviders: [
    provideIcons({
      matDarkModeRound,
      matDesktopMacRound,
      matLightModeRound,
      featherMenu,
      featherX,
    }),
  ],
})
export class HeaderComponent implements OnInit {
  @Input({ transform: booleanAttribute }) transparent = false;
  @Input({ transform: booleanAttribute }) focus = false;
  @Input({ transform: booleanAttribute }) lite = false;
  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (!this.focus) return;

    const scrollPosition = this.window.scrollY;
    this.transparent =
      scrollPosition < (70 * document.documentElement.clientHeight) / 100;
  }
  showMenu = false;
  user?: User;
  theme = 'light';

  constructor(
    private auth: AuthService,
    private themeService: ThemeService,
    @Inject(WINDOW_TOKEN) private window: Window,
  ) {
    themeService.getTheme().subscribe({
      next: (theme: string) => {
        this.theme = theme;
      },
    });
  }

  ngOnInit(): void {
    this.user = this.auth.getUser();
  }

  toggleMenu(): void {
    this.showMenu = !this.showMenu;
  }

  signOut(): void {
    this.auth.signOut();
    this.window.location.assign('/');
  }

  changeTheme(theme: 'dark' | 'light'): void {
    this.themeService.changeTheme(theme, false);
  }

  resetTheme(): void {
    this.themeService.resetPreferredTheme();
  }
}
