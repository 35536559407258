@if (lite) {
  <footer class="space-x-5 py-5 text-sm text-white">
    <a href="/tos" i18n>Terms of Service</a>
    <a href="/privacy" i18n>Privacy Policy</a>
  </footer>
} @else {
  <footer
    class="dark:border-dark-active border-t bg-black py-[72px] text-sm text-white"
  >
    <div
      class="flex flex-col items-center px-4 py-4 lg:grid lg:grid-cols-12 lg:px-0"
    >
      <div class="w-full px-6 lg:col-span-8 lg:col-start-3 lg:px-0">
        <a
          href="/"
          aria-hidden=""
          class="flex items-center text-2xl font-semibold"
        >
          <img
            class="mr-2"
            src="/assets/images/logo-transparent.svg"
            width="32"
            height="32"
            alt=""
          />
          <span>Lysties</span>
        </a>
        <div
          class="dark:border-dark-active grid grid-cols-2 gap-4 border-b border-white py-10 md:grid-cols-3 lg:grid-cols-4"
        >
          <div class="col-span-1">
            <h3 class="mb-4 text-lg font-semibold" i18n>About</h3>
            <ul>
              <li>
                <a
                  href="/tos"
                  class="leading-7 hover:text-slate-400 focus:text-slate-400"
                  i18n
                  >Terms of Service</a
                >
              </li>
              <li>
                <a
                  href="/privacy"
                  class="leading-7 hover:text-slate-400 focus:text-slate-400"
                  i18n
                  >Privacy Policy</a
                >
              </li>
            </ul>
          </div>
          <div class="col-span-1">
            <h3 class="mb-4 text-lg font-semibold" i18n>Cities</h3>
            <ul>
              <li>
                <a
                  href=""
                  class="leading-7 hover:text-slate-400 focus:text-slate-400"
                  i18n
                  >Yaounde, CE</a
                >
              </li>
              <li>
                <a
                  href=""
                  class="leading-7 hover:text-slate-400 focus:text-slate-400"
                  i18n
                  >Douala, LT</a
                >
              </li>
            </ul>
          </div>
          <div class="col-span-1">
            <h3 class="mb-4 text-lg font-semibold" i18n>Languages</h3>
            <ul>
              <li>
                <button
                  class="leading-7 hover:text-slate-400 focus:text-slate-400"
                  i18n
                >
                  English
                </button>
              </li>
              <li>
                <button
                  class="leading-7 hover:text-slate-400 focus:text-slate-400"
                  i18n
                >
                  French
                </button>
              </li>
            </ul>
          </div>
          <div class="col-span-1">
            <h3 class="mb-4 text-lg font-semibold lg:text-right" i18n>
              Connect with Us
            </h3>
            <div class="flex space-x-5 lg:justify-end">
              <a
                href="https://twitter.com/lysties"
                target="_blank"
                rel="noopener"
                i18n-aria-label
                aria-label="Follow us on Twitter"
              >
                <ng-icon name="bootstrapTwitterX" size="32"></ng-icon>
              </a>
              <a
                href="https://instagram.com/lysties"
                target="_blank"
                rel="noopener"
                i18n-aria-label
                aria-label="Follow us on Instagram"
              >
                <ng-icon name="featherInstagram" size="32"></ng-icon>
              </a>
              <a
                href="https://facebook.com/lysties"
                target="_blank"
                rel="noopener"
                i18n-aria-label
                aria-label="Follow us on Facebook"
              >
                <ng-icon name="featherFacebook" size="32"></ng-icon>
              </a>
            </div>
          </div>
        </div>
        <div class="mt-10 flex justify-between">
          <p>&copy; {{ year }}. All rights reserved.</p>
        </div>
      </div>
    </div>
  </footer>
}
