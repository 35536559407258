import {
  Attribute as AttributeDecorator,
  BaseResource,
} from '@lysties/common/api';

import { AttributeCategory } from './attribute-category';

export class Attribute extends BaseResource {
  @AttributeDecorator()
  slug = '';

  @AttributeDecorator()
  name = '';

  @AttributeDecorator(AttributeCategory)
  category = new AttributeCategory();

  constructor(name = '', slug = '', category = '', categorySlug = '') {
    super();

    this.name = name;
    this.slug = slug;
    this.category = new AttributeCategory(category, categorySlug);
  }
}
