import { Attribute, BaseResource } from '@lysties/common/api';
import { Neighborhood } from './neighborhood';

export class Location extends BaseResource {
  @Attribute() address = '';
  @Attribute() longitude = 0;
  @Attribute() latitude = 0;
  @Attribute(Neighborhood) neighborhood = new Neighborhood();

  constructor(init?: Partial<Location>) {
    super(init);

    Object.assign(this, init);
  }

  display(): string {
    return this.neighborhood.display();
  }

  fullAddress(): string {
    return this.address ? `${this.address}, ${this.display()}` : this.display();
  }
}
