<section class="my-16">
  <h2 i18n class="mb-16 text-center text-4xl font-medium">
    Recent activity by <br /><span class="text-accent-base">other users</span>
  </h2>

  <div class="px-4 lg:grid lg:grid-cols-12 lg:px-0">
    <div class="col-span-8 col-start-3">
      <div class="grid grid-cols-12 gap-4">
        @for (review of reviews; track review.id) {
          <div
            ui-card
            class="col-span-12 space-y-4 self-start sm:col-span-6 lg:col-span-4"
          >
            <a
              [routerLink]="['/users', review.user.id]"
              class="flex items-center space-x-4"
            >
              @if (review.user.profile.avatar) {
                <img
                  [src]="
                    review.user.profile.avatar || '/assets/images/avatar.svg'
                  "
                  alt=""
                  aria-hidden="true"
                  class="h-[42px] w-[42px] rounded-full"
                  width="42"
                  height="42"
                />
              } @else {
                <ui-avatar
                  [initials]="review.user.profile.initials()"
                  [size]="42"
                ></ui-avatar>
              }
              <div class="flex flex-col text-sm">
                <p class="font-semibold">
                  {{ review.user.profile.display() }}
                </p>
                <p i18n class="text-muted-base dark:text-dark-muted">
                  Wrote a review
                </p>
              </div>
            </a>

            @if (review.photos.length !== 0) {
              <a
                class="relative mt-4 h-40 w-full"
                [routerLink]="['/businesses', review.businessSlug, 'photos']"
                [queryParams]="{ open: review.photos[0].id }"
              >
                <img
                  [ngSrc]="review.photos[0].image"
                  [alt]="review.photos[0].caption"
                  fill
                  class="rounded object-cover"
                />
              </a>
            }

            <div>
              <h3 class="mb-2 text-xl">
                <a
                  [routerLink]="['/businesses', review.businessSlug]"
                  class="font-bold transition-all duration-300 hover:underline"
                  >{{ review.businessName }}</a
                >
              </h3>
              <ui-rating [rating]="review.rating" [size]="12"></ui-rating>
            </div>
            <div [innerText]="review.comment | truncate: 65"></div>
            @if (review.comment.length > 65) {
              <a
                [routerLink]="['/businesses', review.businessSlug]"
                [queryParams]="{ review: review.id }"
                class="text-accent-base text-sm"
                i18n
                >Read more</a
              >
            }
          </div>
        }

        @if (nextPage !== 1) {
          <div class="col-span-12 text-center">
            <button
              ui-flat-button
              color="primary"
              (click)="loadActivity()"
              i18n
            >
              Load more
            </button>
          </div>
        }
      </div>
    </div>
  </div>
</section>
