import { Attribute, BaseResource } from '@lysties/common/api';

export class Category extends BaseResource {
  @Attribute()
  name = '';

  @Attribute()
  slug = '';

  @Attribute(Category, 'parents')
  parents: Category[] = [];

  @Attribute(undefined, 'icon')
  image = '';

  constructor(name = '', slug = '', image = '', parents: Category[] = []) {
    super();
    this.name = name;
    this.slug = slug;
    this.image = image;
    this.parents = parents;
  }
}
