import { CommonModule } from '@angular/common';
import { Component, Input, booleanAttribute } from '@angular/core';

import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { featherInstagram, featherFacebook } from '@ng-icons/feather-icons';
import { bootstrapTwitterX } from '@ng-icons/bootstrap-icons';

import { ButtonComponent } from '@lysties/shared/ui';

/**
 * Footer component.
 * Provides a standard footer with about and social links.
 */
@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [CommonModule, ButtonComponent, NgIconComponent],
  templateUrl: './footer.component.html',
  viewProviders: [
    provideIcons({
      featherInstagram,
      bootstrapTwitterX,
      featherFacebook,
    }),
  ],
})
export class FooterComponent {
  @Input({ transform: booleanAttribute }) lite = false;
  year = new Date().getFullYear();
}
