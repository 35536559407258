import {
  AfterViewInit,
  Component,
  ElementRef,
  HostBinding,
  Input,
  booleanAttribute,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseComponent } from '../base.component';

/**
 * List of attributes that can be used to add ButtonComponent instances on
 * host attributes to style different variants.
 */
const BUTTON_HOST_ATTRIBUTES: string[] = [
  'ui-button',
  'ui-flat-button',
  'ui-stroked-button',
];

/**
 * Classes that can be added on host attribute per variant and per host attribute.
 */
const BUTTON_STYLE_CLASSES: { [name: string]: { [name: string]: string } } = {
  'ui-button': {
    default: 'bg-white bg-opacity-0 hover:bg-opacity-30',
    secondary: 'bg-white text-black bg-opacity-0 hover:bg-opacity-30',
    primary: 'text-primary-base',
    custom: '',
  },
  'ui-stroked-button': {
    default:
      'bg-white bg-opacity-0 hover:bg-opacity-30 border border-secondary-base focus:bg-secondary-active active:bg-secondary-active disabled:text-black disabled:text-opacity-50 disabled:bg-secondary-base disabled:cursor-default dark:border-dark-active dark:hover:bg-dark-active dark:focus:bg-dark-active',
  },
  'ui-flat-button': {
    default: 'bg-white bg-opacity-0 hover:bg-opacity-30',
    secondary:
      'bg-secondary-base text-black focus:bg-secondary-active active:bg-secondary-active disabled:text-black disabled:text-opacity-50 disabled:bg-secondary-base disabled:cursor-default dark:bg-dark-active dark:text-white dark:focus:bg-dark-active dark:active:bg-dark-active',
    primary:
      'bg-primary-base text-white focus:bg-primary-active active:bg-primary-active disabled:text-white disabled:text-opacity-50 disabled:bg-primary-base disabled:bg-opacity-50 disabled:cursor-default',
    accent:
      'bg-accent-base text-white focus:bg-accent-active active:bg-accent-active disabled:text-white disabled:text-opacity-50 disabled:bg-accent-base disabled:bg-opacity-50 disabled:cursor-default',
  },
};

/**
 * Button component.
 * Provides a standard button with different variants.
 */
@Component({
  selector:
    'button[ui-button], button[ui-flat-button], a[ui-button], a[ui-flat-button], button[ui-stroked-button], a[ui-stroked-button]',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './button.component.html',
})
export class ButtonComponent extends BaseComponent implements AfterViewInit {
  @Input() color: 'default' | 'primary' | 'accent' | 'secondary' | 'custom' =
    'default';
  @Input({ transform: booleanAttribute }) disabled = false;
  @Input({ transform: booleanAttribute }) large = false;
  @Input({ transform: booleanAttribute }) full = false;
  @Input({ transform: booleanAttribute }) icon = false;
  @HostBinding('attr.disabled') get valid() {
    return this.disabled ? 'disabled' : null;
  }

  constructor(_elementRef: ElementRef<HTMLElement>) {
    super(_elementRef);

    // Button base classes:
    this.getHostElement().classList.add(
      'inline-flex',
      'items-center',
      'justify-center',
      'rounded-full',
      'transition-all',
      'duration-300',
      'font-semibold',
      'text-nowrap',
    );
  }

  ngAfterViewInit(): void {
    // Add classes for button variant:
    BUTTON_HOST_ATTRIBUTES.forEach((hostAttribute) => {
      if (this.hasHostAttributes(hostAttribute) && this.color !== 'custom') {
        this.addToClassList(BUTTON_STYLE_CLASSES[hostAttribute][this.color]);
      }
    });

    // Add classes for button size:
    if (this.large) {
      this.addToClassList('px-8 py-4 text-lg leading-8');
    } else {
      this.addToClassList('px-4 py-3 text-sm leading-[18px]');
    }

    // Add classes for button width:
    if (this.full) {
      this.addToClassList('w-full');
    }

    // Add classes for button icon:
    if (this.icon) {
      this.addToClassList('h-[42px] max-w-[42px] w-full min-w-[42px]');
      this.getHostElement().classList.remove('px-4');
    }
  }
}
