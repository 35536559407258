import { Category } from '@lysties/classification/data-access';
import { Attribute, BaseResource } from '@lysties/common/api';
import { City } from '@lysties/locations/data-access';
import { Photo } from '@lysties/photos/data-access';

export class Highlight extends BaseResource {
  @Attribute()
  title = '';

  @Attribute()
  callToAction = '';

  @Attribute(Photo)
  photo = new Photo();

  @Attribute(Category)
  category = new Category();

  @Attribute(City)
  city = new City();

  constructor(init?: Partial<Highlight>) {
    super(init);

    Object.assign(this, init);
  }
}
