<section class="relative">
  <div class="relative flex min-h-[600px] flex-col" #container aria-live="off">
    @if (highlights$ | async; as highlights) {
      @for (
        highlight of highlights.data;
        track highlight.id;
        let index = $index
      ) {
        <div
          class="absolute left-0 right-0 top-0 h-full opacity-0 transition-all duration-1000 ease-in-out"
          role="tabpanel"
          aria-roledescription="slide"
          [attr.aria-label]="index + 1 + ' of ' + highlights.data.length"
          [id]="'slide-' + (index + 1)"
          [ngClass]="{
            'opacity-0': index !== activeSlideIndex,
            'opacity-100': index === activeSlideIndex
          }"
        >
          <div class="relative h-[600px] w-full">
            <img
              [ngSrc]="highlight.photo.image"
              [alt]="highlight.photo.caption"
              fill
              priority
              class="object-cover"
            />
            <div
              class="absolute bottom-0 left-0 right-0 top-0 z-[1] flex flex-col bg-black bg-opacity-20 px-4 pb-20 pt-10 text-white lg:grid lg:grid-cols-12 lg:px-0"
            >
              <div class="mt-auto lg:col-span-6 lg:col-start-3">
                <div class="flex flex-col space-y-8">
                  <h2 class="text-5xl font-bold">
                    {{ highlight.title }}
                  </h2>
                  <a
                    [routerLink]="['/businesses']"
                    [queryParams]="{
                      cat: highlight.category.slug,
                      city: highlight.city.slug
                    }"
                    [queryParamsHandling]="'merge'"
                    ui-flat-button
                    color="primary"
                    class="self-start"
                    >{{ highlight.callToAction }}</a
                  >
                </div>
              </div>
              <div class="mt-4 lg:col-span-2 lg:mt-auto">
                <div class="flex flex-col items-end">
                  <h3 class="font-bold">
                    <a
                      [routerLink]="[
                        '/businesses',
                        highlight.photo.businessSlug
                      ]"
                      >{{ highlight.photo.businessName }}</a
                    >
                  </h3>
                  <p>
                    <span i18n>Photo by</span>&nbsp;<a
                      [routerLink]="['/users', highlight.photo.authorId]"
                      class="font-bold"
                      >{{ highlight.photo.authorName }}</a
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    }
  </div>
  <div
    class="absolute bottom-10 left-0 right-0 z-[1] px-4 lg:grid lg:grid-cols-12 lg:px-0"
  >
    <div
      class="flex space-x-4 lg:col-span-6 lg:col-start-3"
      role="tablist"
      aria-label="Slides"
      aria-label-i18n
    >
      @if (highlights$ | async; as highlights) {
        @for (
          highlight of highlights.data;
          track highlight.id;
          let index = $index
        ) {
          <button
            class="h-2 w-24 overflow-hidden rounded-full bg-white bg-opacity-50 transition-none ease-in-out before:block before:h-2 before:w-0 before:rounded-full before:bg-white before:transition-all before:ease-in-out before:content-['']"
            role="tab"
            [tabIndex]="index === activeSlideIndex ? 0 : -1"
            [attr.aria-selected]="index === activeSlideIndex ? true : false"
            [attr.aria-controls]="'slide-' + (index + 1)"
            (click)="setActiveSlideIndex(index)"
            [ngClass]="{
              'before:w-full before:duration-[5000ms]':
                index === activeSlideIndex,
              'before:w-0 before:duration-0': index !== activeSlideIndex
            }"
          ></button>
        }
      }
    </div>
  </div>
</section>
