<div
  class="border-secondary-base dark:border-dark-active dark:bg-dark-base inline-flex flex-col space-y-4 rounded-lg border bg-white p-3 shadow"
>
  <div class="flex">
    <div class="mr-auto flex items-center">
      <button
        type="button"
        class="min-w-48 text-nowrap pl-1.5 text-left text-sm font-medium leading-3"
        i18n
        (click)="toggleYears()"
        data-testid="toggle-years"
      >
        @if (mode === 'days') {
          {{ months[month] }} {{ year }}
        } @else if (mode === 'years') {
          {{ years[0] }} - {{ years[years.length - 1] }}
        }
        <ng-icon name="bootstrapCaretDownFill" size="10"></ng-icon>
      </button>
    </div>
    <div class="flex space-x-5">
      <button
        type="button"
        aria-label="Previous month"
        aria-label-i18n
        (click)="previous()"
        class="flex flex-col items-center"
        data-testid="previous"
      >
        <ng-icon name="heroChevronLeftSolid"></ng-icon>
      </button>
      <button
        type="button"
        aria-label="Next month"
        aria-label-i18n
        (click)="next()"
        class="flex flex-col items-center"
        data-testid="next"
      >
        <ng-icon name="heroChevronRightSolid"></ng-icon>
      </button>
    </div>
  </div>

  @if (mode === 'days') {
    <table class="w-full border-collapse space-y-1" #daysTable>
      <thead>
        <tr class="flex">
          @for (day of days; track day; let index = $index) {
            <th
              scope="col"
              [attr.aria-label]="days[index]"
              class="text-muted-base dark:text-dark-muted flex-1 text-center text-xs font-medium"
            >
              {{ day | slice: 0 : 2 }}
            </th>
          }
        </tr>
      </thead>
      <tbody role="rowgroup">
        @for (week of calendarDays.keys(); track week) {
          <tr class="mt-2 flex w-full">
            @for (day of calendarDays.get(week); track day.day) {
              <td class="flex-1 text-center text-xs font-medium">
                <button
                  type="button"
                  [attr.aria-label]="
                    months[day.month] + ' ' + day.day + ' ' + day.year
                  "
                  class="hover:bg-secondary-active dark:hover:bg-dark-active dark:focus:bg-dark-active focus:bg-secondary-active w-full rounded-sm p-1 transition-all duration-300 ease-in-out"
                  [ngClass]="{
                    'bg-secondary-active dark:bg-dark-active':
                      day.day === today.getDate() &&
                      day.month === today.getMonth() &&
                      day.year === today.getFullYear(),
                    'bg-primary-base text-white':
                      selected &&
                      day.day === selected.getDate() &&
                      day.month === selected.getMonth() &&
                      day.year === selected.getFullYear(),
                    'text-secondary-active hover:text-muted-base dark:text-dark-active dark:hover:text-dark-muted':
                      day.month !== month
                  }"
                  (click)="selectDay(day)"
                >
                  {{ day.day }}
                </button>
              </td>
            }
          </tr>
        }
      </tbody>
    </table>
  } @else if (mode === 'years') {
    <div class="grid grid-cols-4 gap-4">
      @for (year of years; track year) {
        <button
          type="button"
          class="hover:bg-secondary-active dark:hover:bg-dark-active dark:focus:bg-dark-active focus:bg-secondary-active rounded-sm p-1 text-xs transition-all duration-300 ease-in-out"
          [ngClass]="{
            'bg-secondary-active dark:bg-dark-active':
              year === today.getFullYear(),
            'bg-primary-base text-white':
              year === (selected ? selected.getFullYear() : 0)
          }"
          (click)="selectYear(year)"
        >
          {{ year }}
        </button>
      }
    </div>
  }
</div>
