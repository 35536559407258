import { Attribute } from '@lysties/classification/data-access';
import {
  Attribute as AttributeDecorator,
  BaseResource,
} from '@lysties/common/api';

export class Feature extends BaseResource {
  @AttributeDecorator()
  available: 'Y' | 'N' = 'N';

  @AttributeDecorator(Attribute)
  attribute: Attribute = new Attribute();
}
